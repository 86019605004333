import moment from "moment";
import localization from "moment/locale/de";
import React from "react";
import Calendar from "react-big-calendar";
import { connect } from "react-redux";
import notify from "devextreme/ui/notify";
import { Popover, OverlayTrigger } from "react-bootstrap";
import {
  fetchAvailableAbsenceStates,
  fetchAvailableAbsenceTypes,
  postAbsenceEntry,
  fetchRemainingDaysOfAbsence,
  fetchAllAbsenceEntries,
  fetchAllAbsenceEntriesByOrganizationUnit,
  deleteAbsenceEntry,
  fetchAvailableNextValidStates,
  fetchAvailableAbsenceTypesByRight,
  fetchEmployeeAbsenceEntries
} from "../../../../actions/absenceActions";
import {
  authenticate,
  getUserHasRight
} from "../../../../actions/identityActions";
import { fetchOrganizationUnits } from "../../../../actions/organizationUnitActions";
import store from "../../../../store";
import "../../../core/Icons/Icons";
import Legend from "../../Shared/legend/Legend";
import MonthOverviewAllEmployeesTable from "./MonthOverviewAllEmployeesTable";
import {
  editAbsenceEntryRight,
  editOwnAbsenceEntryRight,
  deleteAbsenceEntryRight,
  addOwnAbsenceEntryRight,
  addAbsenceEntryRight
} from "../../../../helpers/Rights";
import { Jumbotron, Container } from "react-bootstrap";
import { popoverContent } from "./MonthOverviewAllEmployeesTableCell";
import { AbsenceEntryPopup } from "./AbsenceEntryPopup";
import { Popup, TextBox, Button } from "devextreme-react";
import { getMainOrganizationUnit } from "../../../../helpers/organizationUnitHelper";

//Wochentagsabkürzungen auf deutsch
moment()
  .local("de", localization)
  .format("LLL");

export const iconStyleArrowLeft = {
  fontSize: "20",
  display: "inline",
  margin: "auto 10px auto 0px"
};

export const iconStyleArrowRight = {
  fontSize: "20",
  display: "inline",
  margin: "auto 0px auto 10px"
};

var newEvent = {
  title: "Neuer Eintrag",
  start: null,
  end: null,
  comments: "",
  absenceEntryId: -1
};

//Localizer für Kalender aus lokalisiertem "moment" Objekt erstellen
const localizer = Calendar.momentLocalizer(moment);

//KalenderMessages Objekt für das überschreiben der Menüführungsbegriffe
const messages = {
  allDay: 'ganztags',
  previous: 'zurück',
  next: 'vor',
  today: 'Heute',
  month: 'Monat',
  week: 'Woche',
  day: 'Tag',
  agenda: 'Agenda',
  date: 'Datum',
  time: 'Zeit',
  event: 'Ereignis', // Or anything you want
  showMore: total => `+ ${total} Ereignisse`
}

class MonthOverviewAllEmployees extends React.Component {
  constructor(props) {
    super(props);
    const { calendarVisible } = this.props.match.params;
    const { id } = this.props.match.params;
    const { date } = this.props.match.params;
    this.state = {
      popupVisible: false,
      passwordVerificationBeforeSaveVisible: false,
      passwordVerificationBeforeDeleteVisible: false,
      title: "",
      absenceType: null,
      isEditable: false,
      absenceEntryId: -1,
      absenceStates: [],
      absenceStatus: null,
      start: null,
      end: null,
      comment: "",
      absenceHistories: [],
      password: "",
      calendarVisible: parseInt(calendarVisible ? calendarVisible : 0),
      rowUser: null,
      date: date,
      selectedOrganizationUnit: id ? parseInt(id) : getMainOrganizationUnit(this.props.identity.user.Employee
        .organizationUnitMembershipTimespans),
      validNextStates: []
    };

    this.hideInfo = this.hideInfo.bind(this);
    this.handleSaveAbsence = this.handleSaveAbsence.bind(this);
    this.saveAbsence = this.saveAbsence.bind(this);
    this.onAbsenceTypeChanged = this.onAbsenceTypeChanged.bind(this);
    this.onAbsenceStateChanged = this.onAbsenceStateChanged.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.onCommentChange = this.onCommentChange.bind(this);
    this.onPasswordChanged = this.onPasswordChanged.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.onSelecting = this.onSelecting.bind(this);
    this.fetchRemainingDaysOfAbsence = this.fetchRemainingDaysOfAbsence.bind(
      this
    );
    this.handleCheckBox = this.handleCheckBox.bind(this);
    this.treeView_itemSelectionChanged = this.treeView_itemSelectionChanged.bind(
      this
    );
    this.treeView_deselectAll = this.treeView_deselectAll.bind(this);
    this.deleteAbsenceEntry = this.deleteAbsenceEntry.bind(this);
    this.handleDeleteAbsence = this.handleDeleteAbsence.bind(this);

    this.handlePasswordVerificationBeforeSaveVisibility = this.handlePasswordVerificationBeforeSaveVisibility.bind(this);
    this.handlePasswordVerificationBeforeDeleteVisibility = this.handlePasswordVerificationBeforeDeleteVisibility.bind(this);
  }

  componentWillMount() {
    store.dispatch(fetchEmployeeAbsenceEntries(this.props.identity.user.Id));
    store.dispatch(fetchAvailableAbsenceTypes());
    //store.dispatch(fetchAllAbsenceEntries());
    store.dispatch(fetchAvailableAbsenceStates());
    store.dispatch(fetchOrganizationUnits());
    store.dispatch(
      getUserHasRight(
        this.props.identity.user,
        editAbsenceEntryRight.id,
        getMainOrganizationUnit(this.props.identity.user.Employee
          .organizationUnitMembershipTimespans)
      )
    );
    store.dispatch(
      getUserHasRight(
        this.props.identity.user,
        editOwnAbsenceEntryRight.id,
        getMainOrganizationUnit(this.props.identity.user.Employee
          .organizationUnitMembershipTimespans)
      )
    );
    store.dispatch(
      getUserHasRight(
        this.props.identity.user,
        deleteAbsenceEntryRight.id,
        getMainOrganizationUnit(this.props.identity.user.Employee
          .organizationUnitMembershipTimespans)
      )
    );
    store.dispatch(
      getUserHasRight(
        this.props.identity.user,
        addOwnAbsenceEntryRight.id,
        getMainOrganizationUnit(this.props.identity.user.Employee
          .organizationUnitMembershipTimespans)
      )
    );
    store.dispatch(
      getUserHasRight(
        this.props.identity.user,
        addAbsenceEntryRight.id,
        getMainOrganizationUnit(this.props.identity.user.Employee
        .organizationUnitMembershipTimespans)
      )
    );
    if (this.state.selectedOrganizationUnit) {
      store.dispatch(
        fetchAllAbsenceEntriesByOrganizationUnit(
          this.state.selectedOrganizationUnit
        )
      );
    }
  }

  render() {
    return (
      <Jumbotron>

        <Container className="with-background" fluid={true}>
          {(!this.state.calendarVisible || this.state.calendarVisible === 0) && <Legend />}
          {(!this.state.calendarVisible || this.state.calendarVisible === 0) && <MonthOverviewAllEmployeesTable
            rowCount={this.props.allAbsenceEntries.length}
            data={this.props.allAbsenceEntries}
            availableAbsenceTypes={this.props.availableAbsenceTypes}
            availableAbsenceStates={this.props.availableAbsenceStates}
            allAbsenceEntries={this.props.allAbsenceEntries}
            organizationUnits={this.props.organizationUnits}
            onCellClick={this.onCellClick}
            treeView_itemSelectionChanged={this.treeView_itemSelectionChanged}
            treeView_deselectAll={this.treeView_deselectAll}
            selectedOrganizationUnit={this.state.selectedOrganizationUnit}
            date={this.state.date}
          />}
          {this.state.calendarVisible === 1 &&
            <Calendar
              messages={messages}
              selectable={true}
              localizer={localizer}
              defaultDate={new Date()}
              defaultView="month"
              views={[ 'month', 'agenda' ]}
              events={this.props.employeeAbsenceEntries}
              onSelectEvent={event => this.openModalCalendar(event)}
              style={{ height: "100vh" }}
              onSelectSlot={this.onSelecting}
              eventPropGetter={(eventStyleGetter)}
              components={{
                event: Event
              }}
            />}
          <AbsenceEntryPopup
            popupVisible={this.state.popupVisible}
            hideInfo={this.hideInfo}
            title={this.state.title}
            handleSaveAbsence={this.handleSaveAbsence}
            availableAbsenceTypes={this.props.availableAbsenceTypes}
            absenceType={this.state.absenceType}
            onAbsenceTypeChanged={this.onAbsenceTypeChanged}
            isEditable={this.state.isEditable}
            absenceEntryId={this.state.absenceEntryId}
            absenceStates={this.state.absenceStates}
            absenceStatus={this.state.absenceStatus}
            onAbsenceStateChanged={this.onAbsenceStateChanged}
            start={this.state.start}
            handleChangeStart={this.handleChangeStart}
            end={this.state.end}
            handleChangeEnd={this.handleChangeEnd}
            onCommentChange={this.onCommentChange}
            absenceHistories={this.state.absenceHistories}
            onPasswordChanged={this.onPasswordChanged}
            deleteAbsenceEntry={this.handleDeleteAbsence}
            rowUser={this.state.rowUser}
            remainingDaysOfAbsence={this.state.remainingDaysOfAbsence}
            appliedDaysOfAbsence={this.state.appliedDaysOfAbsence}
            identity={this.props.identity}
            isHalfDay={this.state.isHalfDay}
            handleCheckBox={this.handleCheckBox}
          />

          <Popup
            title={"Passwort zur Verifizierung"}
            visible={this.state.passwordVerificationBeforeSaveVisible}
            closeOnOutsideClick={true}
            showTitle={true}
            onHiding={this.handlePasswordVerificationBeforeSaveVisibility}
            width={320}
            height={170}
          >
            <div className={'dx-field'}>
              <TextBox id='passwordField' mode={'password'} placeholder='Passwort eingeben...' value={this.state.password} onValueChanged={this.onPasswordChanged} />
            </div>
            <Button text="Speichern" type="default" useSubmitBehavior={false} onClick={this.saveAbsence} style={{ width: '100%', marginLeft: '0px', float: 'none' }} />
          </Popup>
          <Popup
            title={"Passwort zur Verifizierung delete"}
            visible={this.state.passwordVerificationBeforeDeleteVisible}
            closeOnOutsideClick={true}
            showTitle={true}
            onHiding={this.handlePasswordVerificationBeforeDeleteVisibility}
            width={320}
            height={170}
          >
            <div className={'dx-field'}>
              <TextBox id='passwordField' mode={'password'} placeholder='Passwort eingeben...' value={this.state.password} onValueChanged={this.onPasswordChanged} />
            </div>
            <Button text="Löschen" type="default" useSubmitBehavior={false} onClick={this.deleteAbsenceEntry} style={{ width: '100%', marginLeft: '0px', float: 'none' }} />
          </Popup>
        </Container>
      </Jumbotron>
    );
  }

  treeView_deselectAll(e) {
    store.dispatch(fetchAllAbsenceEntries());
    this.setState({
      selectedOrganizationUnit: e.value
    });
  }

  treeView_itemSelectionChanged(e) {
    if (!e.component.getSelectedNodesKeys()[ 0 ])
      return;
    store.dispatch(
      fetchAllAbsenceEntriesByOrganizationUnit(
        e.component.getSelectedNodesKeys()[ 0 ]
      )
    );
    store.dispatch(
      getUserHasRight(
        this.props.identity.user,
        editAbsenceEntryRight.id,
        e.component.getSelectedNodesKeys()[ 0 ]
      )
    );
    store.dispatch(
      getUserHasRight(
        this.props.identity.user,
        editOwnAbsenceEntryRight.id,
        e.component.getSelectedNodesKeys()[ 0 ]
      )
    );
    store.dispatch(
      getUserHasRight(
        this.props.identity.user,
        deleteAbsenceEntryRight.id,
        e.component.getSelectedNodesKeys()[ 0 ]
      )
    );
    store.dispatch(
      getUserHasRight(
        this.props.identity.user,
        addOwnAbsenceEntryRight.id,
        e.component.getSelectedNodesKeys()[ 0 ]
      )
    );
    store.dispatch(
      getUserHasRight(
        this.props.identity.user,
        addAbsenceEntryRight.id,
        e.component.getSelectedNodesKeys()[ 0 ]
      )
    );
    this.setState({
      selectedOrganizationUnit: e.component.getSelectedNodesKeys()[ 0 ]
    });
  }

  hideInfo() {
    this.setState({
      password: "",
      popupVisible: false
    });
  }

  handleSaveAbsence(e) {
    this.setState({
      passwordVerificationBeforeSaveVisible: !this.state.passwordVerificationBeforeSaveVisible,
      password: ''
    })
  }

  handleDeleteAbsence(e) {
    this.setState({
      passwordVerificationBeforeDeleteVisible: !this.state.passwordVerificationBeforeDeleteVisible,
      password: ''
    })
  }

  saveAbsence(e) {
    var start =
      this.state.absenceEntryId === -1
        ? this.state.start.toJSON()
        : this.state.start;
    var end =
      this.state.absenceEntryId === -1
        ? this.state.end.toJSON()
        : this.state.end;
    store
      .dispatch(
        authenticate(this.props.identity.user.UserName, this.state.password)
      )
      .then(response => {
        if (response.status === 200) {
          store.dispatch(
            postAbsenceEntry(
              this.state.absenceEntryId,
              this.state.absenceType.absenceTypeId,
              start,
              end,
              this.state.comment,
              this.state.rowUser.userId,
              this.props.identity.user.Id,
              this.state.absenceStatus.absenceStatusId,
              this.state.calendarVisible && this.state.calendarVisible === 1 ? false : true,
              this.state.calendarVisible && this.state.calendarVisible === 1 ? null : this.state.selectedOrganizationUnit,
              this.state.isHalfDay
            )
          ).then(response => {
            notify(response.errorMessage, response.type, 2000);
            if (response.type === "success") {
              this.hideInfo();
              this.setState({
                passwordVerificationBeforeSaveVisible: false
              });
            }
          });
        } else {
          notify("Passwort ist nicht korrekt. Bitte erneut eingeben.", "error", 2000);
        }
      });
  }

  onAbsenceTypeChanged(e) {
    var absenceStates = [];
    var absenceStatus = null;
    //Iteration über alle AbsenceStates, die vom Controller geliefert werden
    this.props.availableAbsenceStates.forEach(function (value) {
      //Dieser Teil ist wichtig für die SelectBox im AbsenceEntryPopup. Wir können nicht als Source alle props.AbsenceStates verwenden und dann den OnCreateStatus als selectedValue, da dieser nicht identisch ist mit dem entsprechenden AbsenceStatus in props.AbsenceStates.
      //Daher suchen wir in props.AbsenceStates den AbsenceStatus mit der gleichen absenceStatusId wie der OnCreateStatus und setzen den selectedValue in der selectBox nicht auf den OnCreateStatus, sondern auf den gefunden Wert.
      if (e.value.onCreateStatus.absenceStatusId === value.absenceStatusId) {
        absenceStates.push(value);
        absenceStatus = value;
      }
      //Anschließend suchen wir in den restlichen props.AbsenceStates die Werte, die auch in den ValidNextStates vorkommen und fügen auch diese der Liste absenceStates hinzu.
      e.value.onCreateStatus.ValidNextStates.forEach(function (value2) {
        if (value.absenceStatusId === value2.nextStatusId)
          absenceStates.push(value);
      });
    });
    //Hier werden alle Werte in den State geschrieben und somit dem AbsenceEntryPopup zur Verfügung gestellt
    this.setState({
      absenceType: e.value,
      title: e.value.absenceTypeDisplayString,
      absenceStatus: absenceStatus,
      absenceStates: absenceStates
    });
  }

  onSelecting(e) {
    var date = e.start;
    date.setHours(date.getHours() + 8);
    newEvent.start = date;
    newEvent.end = date;
    newEvent.absenceType = this.props.availableAbsenceTypes[ 0 ];
    newEvent.absenceStatus = this.props.availableAbsenceTypes[ 0 ].onCreateStatus;
    newEvent.title = this.props.availableAbsenceTypes[ 0 ].absenceTypeDisplayString;
    newEvent.comments = "";
    newEvent.isEditable = true;
    newEvent.absenceHistoryString = "";
    newEvent.isHalfDay = false;
    this.openModalCalendar(newEvent);
  }

  onAbsenceStateChanged(e) {
    this.setState({
      absenceStatus: e.value,
      isEditable: e.value.isEditable
    });
  }

  handleChangeStart(e) {
    this.setState({
      start: e.value
    });
  }

  handleChangeEnd(e) {
    this.setState({
      end: e.value
    });
  }

  handleCheckBox(e) {
    var { checked } = e.target;
    this.setState({
      isHalfDay: checked
    });
  }

  onCommentChange(e) {
    this.setState({
      comment: e.value
    });
  }

  onPasswordChanged(e) {
    this.setState({
      password: e.value
    });
  }

  deleteAbsenceEntry() {

    store
      .dispatch(
        authenticate(this.props.identity.user.UserName, this.state.password)
      )
      .then(response => {
        if (response.status === 200) {
          store.dispatch(
            deleteAbsenceEntry(
              this.state.absenceEntryId,
              true,
              this.props.identity.user.userId,
              this.state.selectedOrganizationUnit,
              this.state.comment
            )
          ).then(response => {
            if (response.status === 200) {
              this.hideInfo();
              this.setState({
                passwordVerificationBeforeDeleteVisible: false
              });
            }

            if (response.status === 400) {
              notify("Eintrag konnte nicht gelöscht werden.", "error", 2000);
            }

          });
        } else {
          notify("Passwort ist nicht korrekt. Bitte erneut eingeben.", "error", 2000);
        }
      });
  }

  fetchRemainingDaysOfAbsence(rowUser) {
    store
      .dispatch(fetchRemainingDaysOfAbsence(rowUser.employeesId))
      .then(response => {
        this.setState({
          remainingDaysOfAbsence:
            response.remainingDaysOfAbsence.remainingDaysOfAbsence,
          appliedDaysOfAbsence:
            response.remainingDaysOfAbsence.appliedDaysOfAbsence
        });
      });
  }

  fetchAvailableNextValidStates(
    absenceEntryId,
    userId,
    organizationUnitsId,
    entry,
    absenceEmployeesId
  ) {
    store.dispatch(fetchAvailableAbsenceTypesByRight(organizationUnitsId, userId, absenceEmployeesId)).then(
      json => {
        store
          .dispatch(
            fetchAvailableNextValidStates(
              absenceEntryId,
              userId,
              organizationUnitsId,
              absenceEmployeesId
            )
          )
          .then(response => {
            entry.absenceStatus.ValidNextStates = response;
          })
          .then(response => {
            this.openModal(entry);
          });
      })
  }

  onCellClick(entry, cellDate, rowUser) {
    if (entry) {
      entry.rowUser = rowUser;
      this.fetchRemainingDaysOfAbsence(
        entry.rowUser
      );
      this.fetchAvailableNextValidStates(
        entry.absenceEntryId,
        this.props.identity.user.Id,
        this.state.selectedOrganizationUnit,
        entry,
        rowUser.employeesId
      );
    } else {
      var date = entry ? entry.start : new Date(cellDate.startOf("day"));
      date.setHours(date.getHours() + 8);
      newEvent.start = date;
      newEvent.end = date;
      newEvent.absenceType = this.props.availableAbsenceTypes[ 0 ];
      newEvent.absenceStatus = this.props.availableAbsenceTypes[ 0 ].onCreateStatus;
      newEvent.title = this.props.availableAbsenceTypes[ 0 ].absenceTypeDisplayString;
      newEvent.comments = "";
      newEvent.isEditable = true;
      newEvent.absenceHistoryString = "";
      newEvent.rowUser = rowUser;
      newEvent.isHalfDay = false;
      this.fetchRemainingDaysOfAbsence(
        rowUser
      );
      this.fetchAvailableNextValidStates(
        -1,
        this.props.identity.user.Id,
        this.state.selectedOrganizationUnit,
        newEvent,
        rowUser.employeesId
      );
    }
  }

  openModalCalendar(event) {
    event.rowUser = this.props.identity.user.Employee;
    this.fetchRemainingDaysOfAbsence(
      event.rowUser
    );
    this.fetchAvailableNextValidStates(
      event.absenceEntryId,
      this.props.identity.user.Id,
      this.state.selectedOrganizationUnit,
      event,
      event.rowUser.employeesId
    );
  }

  openModal(event) {
    var defaultAbsenceType;
    var absenceTypeId = event.absenceType.absenceTypeId;
    this.props.availableAbsenceTypes.forEach(function (value) {
      if (value.absenceTypeId === absenceTypeId) defaultAbsenceType = value;
    });
    var absenceStates = [];
    var absenceStatus = null;
    this.props.availableAbsenceStates.forEach(function (value) {
      if (event.absenceStatus.absenceStatusId === value.absenceStatusId) {
        absenceStates.push(value);
        absenceStatus = value;
      }
      event.absenceStatus.ValidNextStates.forEach(function (value2) {
        if (value.absenceStatusId === value2.nextStatusId)
          absenceStates.push(value);
      });
    });
    var isEditable = event.absenceStatus
      ? event.absenceStatus.isEditable
      : event.isEditable;
    this.setState({
      selectedEvent: event,
      start: event.start,
      end: event.end,
      absenceStatus: absenceStatus,
      absenceStates: absenceStates,
      absenceEntryId: event.absenceEntryId,
      title: event.title,
      absenceType: defaultAbsenceType,
      comment: event.comments,
      isEditable: isEditable,
      popupVisible: true,
      employee: event.employee,
      rowUser: event.rowUser,
      absenceHistories: event.absenceHistories,
      isHalfDay: event.isHalfDay
    });
  }

  handlePasswordVerificationBeforeSaveVisibility() {
    this.setState({
      passwordVerificationBeforeSaveVisible: !this.state.passwordVerificationBeforeSaveVisible
    });
  }

  handlePasswordVerificationBeforeDeleteVisibility() {
    this.setState({
      passwordVerificationBeforeDeleteVisible: !this.state.passwordVerificationBeforeDeleteVisible
    });
  }
}

function checkDateInRange(cellDate, startDate, endDate) {
  var start = new Date(startDate).setHours(0, 0, 0, 0);
  var end = new Date(endDate).setHours(24, 59, 59, 999);
  var toCheck = moment(new Date(cellDate).setHours(12, 0, 0, 0));
  if (toCheck.isBetween(moment(start), moment(end))) {
    return true;
  }
  return false;
}

function eventStyleGetter(event, start, end, isSelected) {
  var style = {
    backgroundColor: event.absenceType.absenceColorHex,
    borderRadius: '0px',
    opacity: 0.8,
    color: 'white',
    border: '0px',
    display: 'block'
  };
  return {
    style: style
  };
}

function Event({ event }) {
  return (
    <OverlayTrigger
      trigger="hover"
      key='top'
      placement='top'
      overlay={
        <Popover
          id={'popover-positioned-top'}
        >
          {popoverContent(event)}
        </Popover>
      }
    >
      <span>
        <div><strong>{event.title}</strong></div>
        {event.desc && ':  ' + event.desc}
      </span>
    </OverlayTrigger>
  )
}

export function giveEntry(values, cellDate) {
  let entries = [];
  values.forEach(function (item) {
    if (checkDateInRange(cellDate, item.start, item.end)) entries.push(item);
  });
  return entries;
}

export function isWeekend(day) {
  let dayNumber = day.getDay();
  return dayNumber === 6 || dayNumber === 0;
}

export function formatTime(dateString) {
  let date = new Date(dateString);
  let hoursString = "" + date.getHours();
  let minuteString = "" + date.getMinutes();
  if (hoursString.length === 1) {
    hoursString = "0" + hoursString;
  }
  if (minuteString.length === 1) {
    minuteString = "0" + minuteString;
  }

  return hoursString + ":" + minuteString + " Uhr";
}

export function formatDate(dateString) {
  let date = new Date(dateString);
  var options = { weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit' };

  return date.toLocaleDateString("de-DE", options);
}

function mapStateToProps(state) {
  const { identity } = state;
  const {
    availableAbsenceTypes,
    availableAbsenceStates,
    allAbsenceEntries,
    employeeAbsenceEntries
  } = state.absenceReducer;
  const { organizationUnits } = state.organizationUnitReducer;
  return {
    availableAbsenceTypes,
    availableAbsenceStates,
    allAbsenceEntries,
    organizationUnits,
    employeeAbsenceEntries,
    identity
  };
}

export default connect(mapStateToProps)(MonthOverviewAllEmployees);
