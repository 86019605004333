import Config from "../Config";
import { authHeader } from "../helpers/requestHelpers";

export const userService = {
  login,
  logout,
  getAll,
  getAllRoles,
  getUserRoles,
  getUserRights,
  getById,
  update,
  delete: _delete
};

const config = new Config();

function login(userName, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ userName, password })
  };

  return fetch(config.backendHost + "/users/authenticate", requestOptions)
    .then(handleResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(user));

      return user;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(config.backendHost + "/users", requestOptions).then(
    handleResponse
  );
}

function getAllRoles() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(config.backendHost + "/users/GetAllRoles", requestOptions).then(
    handleResponse
  );
}

function getUserRoles(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      Id: user.Id,
      UserName: user.UserName,
      Token: user.Token
    })
  };
  console.log("uid=" + user);
  return fetch(config.backendHost + "/users/GetUserRoles", requestOptions).then(
    handleResponse
  );
}

function getUserRights(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      Id: user.Id,
      UserName: user.UserName,
      Token: user.Token
    })
  };
  return fetch(
    config.backendHost + "/users/GetUserRights",
    requestOptions
  ).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(config.backendHost + `/users/${id}`, requestOptions).then(
    handleResponse
  );
}

function update(user) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(user)
  };

  return fetch(config.backendHost + `/users/${user.id}`, requestOptions).then(
    handleResponse
  );
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader()
  };

  return fetch(config.backendHost + `/users/${id}`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
