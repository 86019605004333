import Config from "../Config";
import { authHeader, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export const REQUEST_ABSENCETYPES = "REQUEST_ABSENCETYPES";
export function requestAbsenceTypes() {
  return {
    type: REQUEST_ABSENCETYPES
  };
}

export const RECEIVE_ABSENCETYPES = "RECEIVE_ABSENCETYPES";
export function receiveAbsenceTypes(json) {
  return {
    type: RECEIVE_ABSENCETYPES,
    availableAbsenceTypes: json
  };
}

//Diese Funktion lädt alle Abwesenheitstypen
export function fetchAvailableAbsenceTypes() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return function (dispatch) {
    dispatch(requestAbsenceTypes());
    return fetch(config.backendHost + "/AbsenceTypes/get", requestOptions)
      .then(response => handleResponse(response))
      .then(json => {
        if (json) {
          dispatch(receiveAbsenceTypes(json));
        }
      });
  };
}

//Diese Funktion lädt alle Abwesenheitstypen anhand von ausführendem User, ausgewählten Employee und der OU => wichtig für NextValidStates des onCreateStates
export function fetchAvailableAbsenceTypesByRight(
  organizationUnitsId,
  userId,
  employeeId
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ organizationUnitsId, userId, employeeId })
  };
  return function (dispatch) {
    dispatch(requestAbsenceTypes());
    return fetch(
      config.backendHost + "/AbsenceTypes/getAllByRight",
      requestOptions
    )
      .then(response => handleResponse(response))
      .then(json => dispatch(receiveAbsenceTypes(json)));
  };
}

export const SET_YEAROVERVIEW = "SET_YEAROVERVIEW";
export function setYearOverview(string) {
  return {
    type: SET_YEAROVERVIEW,
    selectedYear: string
  };
}

export const REQUEST_ABSENCESTATES = "REQUEST_ABSENCESTATES";
export function requestAbsenceStates() {
  return {
    type: REQUEST_ABSENCESTATES
  };
}

export const RECEIVE_ABSENCESTATES = "RECEIVE_ABSENCESTATES";
export function receiveAbsenceStates(json) {
  return {
    type: RECEIVE_ABSENCESTATES,
    availableAbsenceStates: json
  };
}

//Diese Funktion lädt alle Abwesenheitsstatus
export function fetchAvailableAbsenceStates() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return function (dispatch) {
    dispatch(requestAbsenceStates());
    return fetch(config.backendHost + "/AbsenceStates/get", requestOptions)
      .then(response => handleResponse(response))
      .then(json => dispatch(receiveAbsenceStates(json)));
  };
}

export const REQUEST_ABSENCEENTRIES = "REQUEST_ABSENCEENTRIES";
export function requestAbsenceEntries() {
  return {
    type: REQUEST_ABSENCEENTRIES
  };
}

export const RECEIVE_ABSENCEENTRIES = "RECEIVE_ABSENCEENTRIES";
export function receiveAbsenceEntries(json) {
  return {
    type: RECEIVE_ABSENCEENTRIES,
    allAbsenceEntries: json
  };
}

//Diese Funktion lädt die Abwesenheiten aller Mitarbeiter
export function fetchAllAbsenceEntries() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return function (dispatch) {
    dispatch(requestAbsenceEntries());
    return fetch(
      config.backendHost + "/Absence/getAbsenceEntriesByEmployees",
      requestOptions
    )
      .then(response => handleResponse(response))
      .then(json => dispatch(receiveAbsenceEntries(json)));
  };
}
//Diese Funktion lädt die Abwesenheiten aller Mitarbeiter einer bestimmten OrganizationUnit
export function fetchAllAbsenceEntriesByOrganizationUnit(organizationUnitsId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(organizationUnitsId)
  };
  return function (dispatch) {
    dispatch(requestAbsenceEntries());
    return fetch(
      config.backendHost + "/Absence/getAbsenceEntriesByOrganizationUnit",
      requestOptions
    )
      .then(response => handleResponse(response))
      .then(json => dispatch(receiveAbsenceEntries(json)));
  };
}

export const RECEIVE_UNFINISHEDABSENCEENTRIES = "RECEIVE_UNFINISHEDABSENCEENTRIES";
export function receiveUnfinishedAbsenceEntries(json) {
  return {
    type: RECEIVE_UNFINISHEDABSENCEENTRIES,
    unfinishedAbsenceEntries: json
  };
}

//Diese Funktion lädt die Abwesenheiten aller Mitarbeiter, die noch eine Aktion vom eingeloggten User verlangen
export function fetchAllAbsenceEntriesWithUnfinishedAbsences(userId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(userId)
  };
  return function (dispatch) {
    dispatch(requestAbsenceEntries());
    return fetch(
      config.backendHost + "/Absence/getAbsenceEntriesWithUnfinishedAbsences",
      requestOptions
    ).then(response => handleResponse(response))
      .then(json => dispatch(receiveUnfinishedAbsenceEntries(json)));
  };
}

export const REQUEST_REMAININGDAYSOFABSENCE = "REQUEST_REMAININGDAYSOFABSENCE";
export function requestRemainingDaysOfAbsence() {
  return {
    type: REQUEST_REMAININGDAYSOFABSENCE
  };
}

export const RECEIVE_REMAININGDAYSOFABSENCE = "RECEIVE_REMAININGDAYSOFABSENCE";
export function receiveRemainingDaysOfAbsence(json) {
  return {
    type: RECEIVE_REMAININGDAYSOFABSENCE,
    remainingDaysOfAbsence: json
  };
}

export function fetchRemainingDaysOfAbsence(employeesId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(employeesId)
  };
  return function (dispatch) {
    dispatch(requestRemainingDaysOfAbsence());
    return fetch(
      config.backendHost + "/Absence/getRemainingDaysOfAbsence",
      requestOptions
    )
      .then(response => handleResponse(response))
      .then(json => dispatch(receiveRemainingDaysOfAbsence(json)));
  };
}

export function fetchAvailableNextValidStates(
  absenceEntryId,
  userId,
  organizationUnitsId,
  absenceEmployeesId
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      absenceEntryId,
      userId,
      organizationUnitsId,
      absenceEmployeesId
    })
  };
  return function (dispatch) {
    dispatch(requestRemainingDaysOfAbsence());
    return fetch(
      config.backendHost + "/Absence/getPossibleNextValidStates",
      requestOptions
    ).then(response => handleResponse(response));
  };
}

export const REQUEST_DAYSOFABSENCE = "REQUEST_DAYSOFABSENCE";
export function requestDaysOfAbsence() {
  return {
    type: REQUEST_DAYSOFABSENCE
  };
}

export const RECEIVE_DAYSOFABSENCE = "RECEIVE_DAYSOFABSENCE";
export function receiveDaysOfAbsence(json) {
  return {
    type: RECEIVE_DAYSOFABSENCE,
    daysOfAbsence: json
  };
}

export function fetchDaysOfAbsence(userId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(userId)
  };
  return function (dispatch) {
    dispatch(requestDaysOfAbsence());
    return fetch(
      config.backendHost + "/Absence/getDaysOfAbsenceByEmploee",
      requestOptions
    )
      .then(response => handleResponse(response))
      .then(json => dispatch(receiveDaysOfAbsence(json)));
  };
}

export const REQUEST_EMPLOYEEABSENCEENTRIES = "REQUEST_EMPLOYEEABSENCEENTRIES";
export function requestEmployeeAbsenceEntries() {
  return {
    type: REQUEST_EMPLOYEEABSENCEENTRIES
  };
}

export const RECEIVE_EMPLOYEEABSENCEENTRIES = "RECEIVE_EMPLOYEEABSENCEENTRIES";
export function receiveEmployeeAbsenceEntries(json) {
  return {
    type: RECEIVE_EMPLOYEEABSENCEENTRIES,
    employeeAbsenceEntries: json
  };
}

//Diese Funktion lädt die Abwesenheiten eines Mitarbeiters
export function fetchEmployeeAbsenceEntries(userId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(userId)
  };
  return function (dispatch) {
    dispatch(requestEmployeeAbsenceEntries());
    return fetch(config.backendHost + "/Absence/get", requestOptions)
      .then(response => handleResponse(response))
      .then(json => dispatch(receiveEmployeeAbsenceEntries(json)));
  };
}

export function postAbsenceEntry(
  absenceEntryId,
  absenceTypeId,
  start,
  end,
  comment,
  employeesId,
  userId,
  absenceStatusId,
  loadAllEntries,
  selectedOrganizationUnit,
  isHalfDay
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      absenceEntryId,
      absenceTypeId,
      start,
      end,
      comment,
      employeesId,
      userId,
      absenceStatusId,
      isHalfDay
    })
  };
  return function (dispatch) {
    return fetch(
      config.backendHost + "/Absence/addNewAbsenceEntry",
      requestOptions
    ).then(
      result => {
        loadAllEntries
          ? selectedOrganizationUnit
            ? dispatch(
              fetchAllAbsenceEntriesByOrganizationUnit(
                selectedOrganizationUnit
              )
            )
            : dispatch(fetchAllAbsenceEntries())
          : dispatch(fetchEmployeeAbsenceEntries(userId));
        return result.json();
      },
      error => {
        return error.json();
      }
    );
  };
}

export function deleteAbsenceEntry(
  absenceEntryId,
  loadAllEntries,
  userId,
  selectedOrganizationUnit,
  comment
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ absenceEntryId, userId, comment })
  };
  return function (dispatch) {
    return fetch(
      config.backendHost + "/Absence/deleteAbsenceEntry",
      requestOptions
    ).then(
      result => {
        loadAllEntries
          ? selectedOrganizationUnit
            ? dispatch(
              fetchAllAbsenceEntriesByOrganizationUnit(
                selectedOrganizationUnit
              )
            )
            : dispatch(fetchAllAbsenceEntries())
          : dispatch(fetchEmployeeAbsenceEntries(userId))
        return result;
      },
      error => {
        return error.json();
      }
    );
  };
}
