class Config {

    constructor() {
        this.backendHost = null;

        //Anpassen

        const hostname = window && window.location && window.location.hostname;
        if(hostname === 'personalmanager.foodgenius.local' || hostname === '192.168.0.18') {
            this.backendHost = 'http://personalmanager.foodgenius.local:37500';
        } else if(hostname === 'personalmanager.foodgenius.de') {
            this.backendHost = 'http://personalmanager.foodgenius.de:5000';
        } else if(hostname === 'demo.personalmanager.gmbh') {
            this.backendHost = 'http://demo.personalmanager.gmbh:5001';
        } else if(hostname === 'localhost') {
            this.backendHost = 'http://localhost:5000';
        }
        else if(/^qa/.test(hostname)||/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(hostname)) {
            this.backendHost = `https://${hostname}/b`;
        } else {
            this.backendHost = 'http://127.0.0.1:5000';
        }

        // this.backendHost = 'https://localhost:44332';
    }
}

export default Config;