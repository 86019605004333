import React from "react";
import { Button, Form, Alert, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import store from "../../../../store";
import { login } from "../../../../actions/identityActions";
import { RECEIVE_EMPLOYEEABSENCEENTRIES } from "../../../../actions/absenceActions";
import { history } from "../../../../helpers/history";
import notify from 'devextreme/ui/notify';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      loading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.redirectLoggedInUser = this.redirectLoggedInUser.bind(this);
  }

  componentDidMount() {
    this.redirectLoggedInUser();
  }

  componentDidUpdate() {
    this.redirectLoggedInUser();
  }

  redirectLoggedInUser() {
    if (this.props.identity.user.Token) {
      history.push("/");
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [ name ]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { username, password } = this.state;
    if (username && password) {
      this.setState({
        loading: true
      });
      store
        .dispatch(login(username, password))
        .then(result => {

          if (!result) {
            notify({ message: "Login fehgeschlagen" }, "error", 2000);
            this.setState({
              loading: false
            });
          }

          store.dispatch({
            type: RECEIVE_EMPLOYEEABSENCEENTRIES,
            employeeAbsenceEntries: []
          })
        }
        );
    }
  }

  render() {
    const { username, password } = this.state;
    return (
      <Form name="form" onSubmit={this.handleSubmit}>
        <Alert variant="danger" show={this.props.identity.user.status === 400}>
          Es gab einen Fehler beim Anmelden. Bitte überprüfe deine Zugangsdaten!
        </Alert>
        <Form.Group controlId="username">
          <Form.Label>E-Mail-Adresse</Form.Label>
          <Form.Control
            type="text"
            name="username"
            value={username}
            required
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Passwort</Form.Label>
          <Form.Control
            type="password"
            name="password"
            value={password}
            required
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Button variant="primary" type="submit">
            Login&nbsp;
            {this.state.loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
          </Button>
        </Form.Group>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  const { identity } = state;
  return {
    identity
  };
}

export default connect(mapStateToProps)(LoginForm);
