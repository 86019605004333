import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import React from "react";
import { Navbar, NavDropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { Route, Router } from "react-router-dom";
import { LOGOUT_USER } from "./actions/identityActions";
import "./components/core/Icons/Icons";
import { PrivateRoute } from './components/core/PrivateRoute/PrivateRoute';
import EmployeeDetailsManagement from './components/pages/Employee/EmployeeDetailsManagement';
import EmployeeManagementTable from "./components/pages/Employee/EmployeeManagementTable";
import Login from "./components/pages/Login/Login";
import Navigation from "./components/pages/Navigation/Navigation";
// import Home from './components/pages/Home/Home';
import Presences from "./components/pages/Presences/Presences";
import Register from "./components/pages/Register/Register";
import MonthOverview from "./components/pages/UserArea/monthOverview/MonthOverview";
import MonthOverviewAllEmployees from "./components/pages/UserArea/monthOverview/MonthOverviewAllEmployees";
import YearView from "./components/pages/UserArea/yearOverview/YearOverview";
import Configuration from "./components/pages/Configuration/Configuration";
import { history } from "./helpers/history";
import logo from "./logo.svg";
import store from "./store";
import Profile from "./components/pages/Profile/Profile";
import RecoverPassword from "./components/pages/RecoverPassword/RecoverPassword";
import ResetPassword from "./components/pages/RecoverPassword/ResetPassword/ResetPassword";
import Roles from "./components/pages/Roles/Roles";
import RolesToEmployee from "./components/pages/Roles/RolesToEmployee";
import UnfinishedAbsenceEntries from "./components/pages/UserArea/unfinishedAbsences/UnfinishedAbsenceEntries";

class App extends React.Component {
  constructor(props) {
    super(props);

    // const { dispatch } = this.props;
    history.listen((location, action) => {
      // clear alert on location change
      // dispatch(alertActions.clear());
    });

    this.handleLogout = this.handleLogout.bind();

  }

  handleLogout() {
    store.dispatch({ type: LOGOUT_USER });
  }

  render() {
    return (
      <Router history={history}>
        <Navbar expand="md" fixed="top" variant="light" style={{ backgroundColor: '#fff' }}>
          <LinkContainer to="/">
            <Navbar.Brand>
              <img
                className="mr-2"
                src={logo}
                alt="Personal.manager"
                width="30px"
              />
              Personal.manager
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            {/* <Nav>
              <LinkContainer to="/login">
                <Nav.Link>Login</Nav.Link>
              </LinkContainer>
            </Nav> */}
            {this.props.identity.user && (
              <div className={!this.props.identity.user.UserName ? 'd-none' : ''}>
                <NavDropdown title={this.props.identity.user.UserName} >
                  <LinkContainer to="/profile">
                    <NavDropdown.Item>Profil</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={this.handleLogout}>Logout</NavDropdown.Item>
                </NavDropdown>
              </div>
            )}
          </Navbar.Collapse>
        </Navbar>
        <div id="content">
          {/* <PrivateRoute exact path="/" component={Home} /> */}
          <PrivateRoute exact path="/" component={Navigation} />
          <Route path="/login" component={Login} />
          <Route path="/RecoverPassword" component={RecoverPassword} />
          <Route path="/ResetPassword" component={ResetPassword} />
          <PrivateRoute path="/presences" component={Presences} />
          <PrivateRoute path="/Employee/Roles/Roles" component={Roles} />
          <PrivateRoute path="/Configuration" component={Configuration} />
          <PrivateRoute path="/Employee/Roles/RolesToEmployee" component={RolesToEmployee} />
          <PrivateRoute path="/Employee/EmployeeManagementTable" component={EmployeeManagementTable} />
          <PrivateRoute path="/Employee/EmployeeDetailsManagement/:id" component={EmployeeDetailsManagement} />
          <PrivateRoute path="/register" component={Register} />
          <PrivateRoute path="/unfinishedAbsenceEntries" component={UnfinishedAbsenceEntries} />
          <PrivateRoute path="/UserArea/monthOverview/MonthOverview" component={MonthOverview} />
          <PrivateRoute path="/UserArea/monthOverview/MonthOverviewAllEmployees/:calendarVisible?/:id?/:date?" component={MonthOverviewAllEmployees} />
          <PrivateRoute path="/presences" component={Presences} />
          <PrivateRoute path="/yearview" component={YearView} />
          <PrivateRoute path="/profile" component={Profile} />
        </div>
      </Router >
    );
  }
}

function mapStateToProps(state) {
  const { identity } = state;
  return {
    identity
  };
}

export default connect(mapStateToProps)(App);
