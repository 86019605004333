
export function getMainOrganizationUnit(organizationUnitList)
{
    var returnOrganizationUnit = organizationUnitList[0].organizationUnitsId;

    for(var organizationUnit of organizationUnitList)
    {
        if(organizationUnit.isMain && new Date(organizationUnit.from) < new Date() && (organizationUnit.to === null || new Date(organizationUnit.to) > new Date()))
        {
            returnOrganizationUnit = organizationUnit.organizationUnitsId;
            break;
        }
    }
    
    return returnOrganizationUnit;
}